import React, { useEffect } from 'react'
import * as s from "./job.module.css"
import Gb from '../assets/gb.svg';
import Tippy from '@tippyjs/react/headless';
import { getRandomIntInclusive } from '../utils/index.js'
import { gsap } from "gsap/dist/gsap";

const tip = `.` + s.tippy

const Job = (props) => {

	const data = props.data;
	// let el = useRef();
	// let q = gsap.utils.selector(el);
  
	useEffect(() => {
		startAnimation();
	},[])

	function onMount() {
	    gsap.killTweensOf(tip);
	    gsap.set(tip, {autoAlpha: 0, transformOrigin:"50% 50%"});
	    gsap.to(tip, {autoAlpha: 1, duration: 0.5, ease: `power2.out`});
	}
	function onHide({ unmount }) {
	    gsap.killTweensOf(tip);
	    gsap.set(tip, {autoAlpha: 0, transformOrigin:"50% 50%"});
	}

	const startAnimation = ()=>{
		const points = gsap.utils.toArray(`.eq-pointc21`);
		let rnd = getRandomIntInclusive(0, points.length-1);
		let point = points[rnd]

		gsap.killTweensOf(points);
		gsap.to(point, {opacity: 1, scale: 1, duration: 1, delay: 0.5 })
		gsap.to(point, {opacity: 0, scale: 0.75, duration: 1, delay: 1.5, onComplete: startAnimation})
	}

	const stopAnimation = ()=>{
		gsap.killTweensOf([`.eq-pointc21`]);
		gsap.set(`.eq-pointc21`, {opacity: 0, scale: 0.75 })
	}

	return (
		<section className={s.window}>
			<div className={s.gbbox}>
				<Gb />
				<div className={s.title}>
					<div className={s.h0} dangerouslySetInnerHTML={{__html: data.h0 }} />
					<div className={s.line} />
					<div className={s.desc} dangerouslySetInnerHTML={{__html: data.desc }} />
					<div className={s.descm} dangerouslySetInnerHTML={{__html: data.descm }} />
				</div>

				<div className={s.points}>
				{
					data.points.map((d,i)=>(

						<Tippy
                          key={i} 
							onMount={onMount}
							onHide={onHide}
                          appendTo={`parent`}
                          render={attrs => (
                            <div 
                              className={s.tippy}
                              data-id={i} 
                              {...attrs}
                            >
                              <div className={s.tippyh0} dangerouslySetInnerHTML={{__html: d.h0 }}/>
                              <div className={s.tippydesc} dangerouslySetInnerHTML={{__html: d.desc }}/>
                            
                              <div className={s.values}>

                              	<div className={s.years}>
                              		<div className={s.year} dangerouslySetInnerHTML={{__html: `2021` }} />
                              		<div className={s.yearval} dangerouslySetInnerHTML={{__html: d.vals[0] }} />
                              	</div>

                              	<div className={s.arrow}>
									<svg  width="40" height="34" viewBox="0 0 40 34">
									  <g transform="translate(-15 1418.355)">
									    <line x1="36.65" transform="translate(-160.051 -1401.542)" fill="none" stroke="#007079" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
									    <path d="M-115.372-1416.941l15.4,15.4-15.4,15.4" transform="translate(-22.902)" fill="none" stroke="#007079" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
									  </g>
									</svg>
                              	</div>

                              	<div className={s.years}>
                              		<div className={s.year} dangerouslySetInnerHTML={{__html: `2026` }} />
                              		<div className={s.yearval} dangerouslySetInnerHTML={{__html: d.vals[1] }} />
                              	</div>

                              </div>
                           
                              	<div className={s.percents}>
                              		<div className={s.circle}>
                              			<div className={s.sector} data-id={i}/>
                              		</div>
                              		<div className={s.txt} dangerouslySetInnerHTML={{__html: d.txt }} />
                              	</div>

                            </div>
                          )}
                        >

							<div 
								className={s.point} 
								data-id={i} 
			                	role = "button"
				                tabIndex="0"
				                onKeyDown={()=>{ stopAnimation(); }}
								onMouseEnter={()=>{
									stopAnimation();
								}}
							>
								<div className={s.pointc0 + ` eq-pointc20`} />
								<div className={s.pointc1 + ` eq-pointc21`} />
								<div className={s.pointc2 + ` eq-pointc22`} />
							</div>

						</Tippy>
					))
				}
				</div>

			</div>

		</section>
)}

export default Job