import React, { useState, useEffect } from 'react'
import * as s from "./slider.module.css"
import ImageFadeIn from "react-image-fade-in";
import { gsap } from "gsap/dist/gsap";
import ReactSlider from 'react-slider'

import { withpath } from '../utils/index.js'

function usePrevious(data){
  const ref = React.useRef();
  React.useEffect(()=>{
    ref.current = data
  }, [data])
  return ref.current
}

const startAnimation = ()=>{
	const point = `.eq-thumb`;
	gsap.killTweensOf([`.eq-thumb`]);
	gsap.to(point, {backgroundColor: `rgba(255,255,255,0.86)`, duration: 0.5})
	gsap.to(point, {backgroundColor: `rgba(255,255,255,0.46)`, duration: 0.5, delay: 0.5, onComplete: startAnimation})
}

const stopAnimation = ()=>{
	gsap.killTweensOf([`.eq-thumb`]);
	gsap.set(`.eq-thumb`, {backgroundColor: `rgba(213,234,244,0.46)` })
}


const Slider = (props) => {

	const data = props.data;
	const [year, setYear] = useState(0);
	const prevCount = usePrevious(year);

	useEffect(() => {
		startAnimation();
	}, [])

	useEffect(() => {
	    let counter0 = { var: data.data[0][prevCount] };
	    gsap.to(counter0, {var: data.data[0][year], duration: 0.5, ease: "power4.out", 
	      onUpdate: function () {
	        document.querySelector(`#eqval0`).textContent = Math.ceil(counter0.var) + `GW`;
	      }, 
	    });

	    let counter1 = { var: data.data[1][prevCount] };
	    gsap.to(counter1, {var: data.data[1][year], duration: 0.5, ease: "power4.out", 
	      onUpdate: function () {
	        document.querySelector(`#eqval1`).textContent = Math.ceil(counter1.var) + `GW`;
	      }, 
	    });
	})

	return (
		<section className={s.window}>
			<div className={s.h0} dangerouslySetInnerHTML={{__html: data.h0 }} />
			<div className={s.line} />
			<div className={s.desc} dangerouslySetInnerHTML={{__html: data.desc }} />

			<div className={s.lampblock}>
				

				<div className={s.light} data-id={year}>
					<svg width="362" height="342" viewBox="0 0 362 342">
					  <defs>
					    <radialGradient id="radial-gradient-slider" cx="0.5" cy="0.5" r="0.5" gradientUnits="objectBoundingBox">
					      <stop offset="0" stopColor="#fff"/>
					      <stop offset="0.759" stopColor="#fff" stopOpacity="0.122"/>
					      <stop offset="1" stopColor="#fff" stopOpacity="0"/>
					    </radialGradient>
					  </defs>
					  <ellipse cx="181" cy="171" rx="181" ry="171" fill="url(#radial-gradient-slider)" style={{mixBlendMode: "screen", isolation: "isolate"}}/>
					  
					</svg>
				</div>

				<div className={s.roundblock}>
					<div className={s.name} dangerouslySetInnerHTML={{__html: data.legend[0] }} />
					<div className={s.val}>
						<span id={`eqval0`} dangerouslySetInnerHTML={{__html: data.data[0][0] + `10GW` }} />
					</div>
				</div>

                <ImageFadeIn 
                  className={s.lamp}
                  src={withpath(`/img/lamp.png`)}
                  srcSet={withpath(`/img/lamp@2x.png 2x`)}
                  alt={`lamp`}
                />

				<div className={s.roundblock}>
					<div className={s.name} dangerouslySetInnerHTML={{__html: data.legend[1] }} />
					<div className={s.val} >
						<span id={`eqval1`}dangerouslySetInnerHTML={{__html: data.data[1][0] + `GW` }} />
					</div>
				</div>

				<div className={s.light2} data-id={year}>
					<svg width="362" height="342" viewBox="0 0 362 342">
					  <defs>
					    <radialGradient id="radial-gradient-slider" cx="0.5" cy="0.5" r="0.5" gradientUnits="objectBoundingBox">
					      <stop offset="0" stopColor="#fff"/>
					      <stop offset="0.759" stopColor="#fff" stopOpacity="0.122"/>
					      <stop offset="1" stopColor="#fff" stopOpacity="0"/>
					    </radialGradient>
					  </defs>
					  
					  <ellipse cx="114.5" cy="108" rx="114.5" ry="108" transform="translate(75 63)" fill="url(#radial-gradient-slider)" style={{mixBlendMode: "screen", isolation: "isolate"}}/>
					</svg>
				</div>

			</div>

			<div 
                role = "button"
                tabIndex="0"
				className={s.slidercontainer}
				onKeyDown={()=>{ stopAnimation(); }}
				onMouseEnter={()=>{ stopAnimation(); }}
			>
		        <ReactSlider
		            className={s.slider}
		            thumbClassName="example-thumb"
		            trackClassName={s.track}
		            defaultValue={[0]}
		            marks
		            markClassName={s.mark}
		            min={0}
		            max={2}
		            renderThumb={(props, state) => <div {...props} className={s.thumb + ` eq-thumb`}><div className={s.gcircle} /><div className={s.circle} /></div>}
		            onChange={val => setYear(val)}
		        />
      		</div>

      		<div className={s.years}>
      			<div className={s.year} dangerouslySetInnerHTML={{__html: data.years[0] }} />
      			<div className={s.year} dangerouslySetInnerHTML={{__html: data.years[1] }} />
      			<div className={s.year} dangerouslySetInnerHTML={{__html: data.years[2] }} />
      		</div>



		</section>
)}

export default Slider