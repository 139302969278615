// extracted by mini-css-extract-plugin
export const window = "clusters-module--window--1zswp";
export const gbbox = "clusters-module--gbbox--tXj-0";
export const title = "clusters-module--title--2CT2a";
export const h0 = "clusters-module--h0--1pTQc";
export const line = "clusters-module--line--Hxl5q";
export const desc = "clusters-module--desc--1N0af";
export const points = "clusters-module--points--cmvRp";
export const point = "clusters-module--point--3p29W";
export const pointc1 = "clusters-module--pointc1--3P8yN";
export const pointc2 = "clusters-module--pointc2--32iTk";
export const pointc0 = "clusters-module--pointc0--M55I_";
export const pointtxt = "clusters-module--pointtxt--1FlSP";
export const tippy = "clusters-module--tippy--MkKp5";
export const tippyh0 = "clusters-module--tippyh0--2yHGz";
export const tippyline = "clusters-module--tippyline--3eEQc";
export const tippytextbox = "clusters-module--tippytextbox--3rvgi";
export const tippytextcircle = "clusters-module--tippytextcircle--3nMnV";
export const tippytexttxt = "clusters-module--tippytexttxt--1p6Qy";