import React, { useEffect } from 'react'
import * as s from "./windmap.module.css"
import { gsap } from "gsap/dist/gsap";
import Windmapsvg from '../assets/windmap.svg';

const stopTween = () => {
	gsap.killTweensOf([`#eq-arrows1 > g`,`#eq-arrows2 > g`]);
	gsap.set([`#eq-arrows1 > g`,`#eq-arrows2 > g`], {opacity: 0, x: 0, y: 0})
}
const startTween = () => {

	const products1 = gsap.utils.toArray(`#eq-arrows1 > g`);
	products1.forEach((product, i) => { 
		let tl1 = gsap.timeline({repeat: -1, repeatDelay: 0, delay: 0.1*i});
		tl1.set(product, {transformOrigin:"50.0% 50.0%", opacity:0,x:10,y:-10});
		tl1.to(product, {opacity:1,x:0,y:0,ease:`power1.in`, duration: 0.5});
		tl1.to(product, {opacity:0,x:-10,y:10,ease:`power1.out`, duration: 0.5});
	});

	const products = gsap.utils.toArray(`#eq-arrows2 > g`);
	products.forEach((product, i) => { 
		let tl2 = gsap.timeline({repeat: -1, repeatDelay: 0, delay: 0.05*i});
		tl2.set(product, {transformOrigin:"50.0% 50.0%", opacity:0,x:10,y:0});
		tl2.to(product, {opacity:1,x:0,y:0,ease:`power1.in`, duration: 0.5});
		tl2.to(product, {opacity:0,x:-10,y:0,ease:`power1.out`, duration: 0.5});
	});
}

const Windmap = (props) => {

	useEffect(() => {
		stopTween();
		startTween();
	}, [])

	return (
		<div className={s.windmap} id={`eq-windmap`}>
			<Windmapsvg />
		</div>
)}

export default Windmap
