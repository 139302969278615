import React, { useEffect } from 'react'
import * as s from "./clusters.module.css"

import Gb from '../assets/gb.svg';
import Tippy from '@tippyjs/react/headless';


import { gsap } from "gsap/dist/gsap";
const isBrowser = typeof window !== "undefined"

function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const startAnimation = ()=>{
	const points = gsap.utils.toArray(`.eq-pointc2`);
	let rnd = getRandomIntInclusive(0, points.length-1);
	let point = points[rnd]
	gsap.killTweensOf([`.eq-pointc2`]);

	if(rnd===0 || rnd===2 || rnd===4){
		gsap.to(point, {backgroundColor: `rgba(255,18,67,0.3)`, duration: 1, delay: 0.5 })
		gsap.to(point, {backgroundColor: `rgba(255,18,67,0.0)`, duration: 1, delay: 1.5, onComplete: startAnimation})
	}else{
		gsap.to(point, {backgroundColor: `rgba(36,55,70,0.3)`, duration: 1, delay: 0.5 })
		gsap.to(point, {backgroundColor: `rgba(36,55,70,0.0)`, duration: 1, delay: 1.5, onComplete: startAnimation})
	}
}

const stopAnimation = ()=>{
	gsap.killTweensOf([`.eq-pointc2`]);
	gsap.set(`.eq-pointc2`, {backgroundColor: `rgba(36,55,70,0.0)` })
}

const tip = `.` + s.tippy


const Clusters = (p) => {
	const data = p.data;
	useEffect(() => {
		startAnimation();
	},[])

	function onMount() {
	    gsap.killTweensOf(tip);
	    gsap.set(tip, {autoAlpha: 0, transformOrigin:"50% 50%"});
	    gsap.to(tip, {autoAlpha: 1, duration: 0.5, ease: `power2.out`});
	}
	function onHide({ unmount }) {
	    gsap.killTweensOf(tip);
	    gsap.set(tip, {autoAlpha: 0, transformOrigin:"50% 50%"});
	}

	return (
		<section className={s.window}>
			<div className={s.gbbox} id={`eq-gbbox`}>
				<Gb />
				<div className={s.title}>
					<div className={s.h0} dangerouslySetInnerHTML={{__html: data.h0 }} />
					<div className={s.line} />
					<div className={s.desc} dangerouslySetInnerHTML={{__html: data.desc }} />
				</div>

				<div className={s.points}>
				{
					data.points.map((d,i)=>(

						<Tippy
							key={i} 
							appendTo={ p.mobile ? "parent" : (isBrowser ? document.body : null)}
							onMount={onMount}
							onHide={onHide}
							render={attrs => (

								<div 
									className={s.tippy}
									data-id={i} 
									{...attrs}
								>
									<div className={s.tippyh0} dangerouslySetInnerHTML={{__html: d.h0 }}/>
									<div className={s.tippyline} />
									{
										d.txt.map((q,j)=>(
											<div className={s.tippytextbox} key={j}>
												<div className={s.tippytextcircle} />
												<div className={s.tippytexttxt} dangerouslySetInnerHTML={{__html: q }} />
											</div>
										))
									}
                            	</div>

                          )}
                        >

							<div 
								className={s.point} 
								data-id={i}
				                role = "button"
				                tabIndex="0"
				                onKeyDown={()=>{
				                }}
								onMouseEnter={()=>{
									stopAnimation();
								}}
							>
								<div className={s.pointc0 + ` eq-pointc0`} />
								<div className={s.pointc1 + ` eq-pointc1`} />
								<div className={s.pointc2 + ` eq-pointc2`} />
								<span className={s.pointtxt} dangerouslySetInnerHTML={{__html: d.id }} />
							</div>

						</Tippy>
					))
				}
				</div>

			</div>

		</section>
)}

export default Clusters