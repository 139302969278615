// extracted by mini-css-extract-plugin
export const bg = "main-module--bg--10YvR";
export const sections = "main-module--sections--38d2G";
export const map = "main-module--map--3WMyb";
export const mapcontainter = "main-module--mapcontainter--33J-4";
export const mapimg = "main-module--mapimg--2d0bV";
export const turbines = "main-module--turbines--39lGA";
export const bg3 = "main-module--bg3--3xQHa";
export const bg4 = "main-module--bg4--1i5yH";
export const gradient = "main-module--gradient--2l9CK";
export const bg8 = "main-module--bg8--dm97i";
export const bg9 = "main-module--bg9--2WY3e";
export const peterhead = "main-module--peterhead--A6QV6";
export const dgraph = "main-module--dgraph--1emSh";
export const h0box = "main-module--h0box--MuulI";
export const h0s4 = "main-module--h0s4--VdDy2";
export const lines4 = "main-module--lines4--2s1ch";
export const desc4 = "main-module--desc4--INgiP";
export const graphbox = "main-module--graphbox--32UYG";
export const graphbox1300 = "main-module--graphbox1300--3EEvk";
export const graphboxhalf = "main-module--graphboxhalf--2mtPK";
export const jobbox = "main-module--jobbox--3zth_";
export const dogger = "main-module--dogger--32RF5";
export const mouse = "main-module--mouse--WWNbW";
export const mousetxt = "main-module--mousetxt--2Kuw2";
export const mousetxtm = "main-module--mousetxtm--3SNJk";