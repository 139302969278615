import React from 'react'
import Main from '../components/main';
import data from '../data/data.json';
import '../styles/index.css'
import 'animate.css/animate.compat.css';


import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.core.globals("ScrollTrigger", ScrollTrigger);

const IndexPage = () => {

  return (
    <div className={`equinor`}>
      <Main 
      	data={data} 
      />
    </div>
  )

}

export default IndexPage